export const gridDefaultColDef = {
  filter: 'agSetColumnFilter',
  resizable: true,
  sortable: true,
};

export const gridColumns = [
  {
    headerName: 'Name',
    field: 'key',
    sort: 'asc',
    minWidth: 300,
  },
  {
    headerName: 'Value',
    field: 'value',
    flex: 1,
    valueGetter: (params) => {
      const value = params?.data?.value;
      return value ? 'Active' : 'Disabled';
    },
  },
];
