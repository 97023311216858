<template>
  <div>
    <h2>Settings</h2>
    <div class="row">
      <div class="col-6">
        <search-filter show-search v-model="query.search"/>
      </div>
    </div>
    <settings-modal
      :setting="setting"
      @hide-and-refresh="hideModalAndRefreshSettings"
    />
    <results-info
      class="mt-3"
      :count="count"
      :selectedCount="selectedCount"
    />
    <ag-grid-vue
      pagination
      :rowData="rowData"
      paginationAutoPageSize
      :columnDefs="columnDefs"
      class="ag-theme-alpine w-100"
      :defaultColDef="defaultColDef"
      :gridOptions="gridOptions"
      @gridReady="onGridReady"
      @cellClicked="onCellClicked"
      rowSelection="single"
      domLayout="autoHeight"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import Settings from '@/services/Api/Settings';
import GridMixin from '@/views/components/grids/GridMixin';
import ResultsInfo from '@/views/components/grids/ResultsInfo.vue';
import SearchFilter from '@/views/components/grids/SearchFilter.vue';
import SettingsModal from '@/views/areas/settings/partials/SettingsModal.vue';
import { gridColumns, gridDefaultColDef } from '@/views/components/grids/settings/SettingsGrid';

export default {
  name: 'Settings',
  components: {
    AgGridVue,
    ResultsInfo,
    SearchFilter,
    SettingsModal,
  },
  mixins: [
    GridMixin,
  ],
  data() {
    return {
      defaultColDef: gridDefaultColDef,
      columnDefs: gridColumns,
      rowData: [],
      setting: null,
    };
  },
  methods: {
    ...mapActions({
      setSettings: 'settings/setSettings',
    }),
    postGridReady() {
      this.refresh();
    },
    async refresh() {
      this.refreshing = true;
      try {
        const response = await Settings.all();
        const settings = response?.data?.data;

        if (response?.status === 200 && settings) {
          this.rowData = settings;
          this.setSettings(settings);
        }
      } finally {
        this.refreshing = false;
      }
    },
    hideModalAndRefreshSettings() {
      this.$bvModal.hide('settings-modal');
      this.refresh();
    },
    onCellClicked({ data }) {
      this.setting = data;
      this.$bvModal.show('settings-modal');
    },
  },
};
</script>
