<template>
  <b-modal hide-footer id="settings-modal" title="Edit Setting">
    <div class="data-entry">
      <div class="row input-row">
        <div class="col-12">
          <ifac-switcher-btn-group
            v-if="settingData"
            :value="settingData.value"
            @switch="settingData.value = $event"
            :subtitle="`Do you want to enable the ${settingData.key} setting?`"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <button
            @click="cancel"
            class="w-100 btn btn-secondary"
          >
            Cancel
          </button>
        </div>
        <div class="col-6">
          <button
            @click="update"
            class="w-100 btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Settings from '@/services/Api/Settings';
import { IfacSwitcherBtnGroup } from '@ifac/ui';

export default {
  name: 'SettingsModal',
  components: {
    IfacSwitcherBtnGroup,
  },
  props: {
    setting: {
      type: Object,
      retuired: true,
    },
  },
  data() {
    return {
      settingData: {
        ...this.setting,
      },
    };
  },
  methods: {
    cancel() {
      this.$bvModal.hide('settings-modal');
    },
    async update() {
      try {
        const response = await Settings.update(this.settingData);
        const conferenceData = response?.data?.data;

        if (response.status === 200 && conferenceData) {
          this.settingData = conferenceData;

          this.$snack.success({
            text: 'The setting was updated successfully!',
          });
          this.$emit('hide-and-refresh');
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.$snack.success({
            text: error.response.data.error.message,
          });
        } else {
          this.$snack.success({
            text: 'There was an error updating the setting, please try again.',
          });
        }
      }
    },
  },
  watch: {
    setting: {
      deep: true,
      handler(setting) {
        this.settingData = {
          ...setting,
        };
      },
    },
  },
};
</script>
